<script setup lang="ts">
import { BaseButton } from '@legacy-studio/library';

import { useDealSumStore } from '../infrastructure/store';
import type { ExpirationTime } from '~/src/features/terminal-expiration-time/types';
import { useSidebarStore } from '~/src/widgets/terminal/store';
import { saveExpirationTime } from '~/src/features/terminal-expiration-time/infrastructure/store';

const dealSumStore = useDealSumStore();

watch(
	() => dealSumStore.activeInvoiceBalance,
	() => {
		if (dealSumStore.activeInvoiceBalance < 1000) {
			dealSumStore.generateList();
		}
	},
	{ immediate: true },
);
const currentDealSum = computed(() => dealSumStore.dealSum);

const useSetDealSum = (sum: number) => {
	const drawerStore = useSidebarStore();
	dealSumStore.setDealSum(sum);
	drawerStore.closeDrawer();
};
</script>

<template>
	<div class="flex flex-col gap-4">
		<BaseButton
			v-for="sum in dealSumStore.list"
			:key="sum"
			color="alternative-dark"
			:class="{
				'bg-gray-700 text-white': currentDealSum === sum,
			}"
			:disabled="currentDealSum === sum"
			:button-text="`$ ${sum}`"
			outline
			@click="useSetDealSum(sum)"
		/>
	</div>
</template>
